import parse from 'parse-duration';
import { z } from 'zod';
import { TFunction } from 'i18next';
import {
	MIN_VALID_RETENTION_PERIOD_MINUTES,
	MAX_VALID_RETENTION_PERIOD_MINUTES,
	MAX_UPLOAD_FILE_SIZE_BYTE,
	MAX_UPLOAD_FILE_SIZE_MB,
	MIN_UPLOAD_FILE_SIZE_MB,
} from '../constants';
import { convertMBtoBytes } from '../../../utils/FormatSize';

export const getPAMRdpTargetFormSchema = (t: TFunction, isUpdating: boolean) =>
	z
		.object({
			name: z
				.string()
				.min(3, { message: t('page.pam.edit.all.form.name.Error', { minLength: 3 }) })
				.max(64, { message: t('page.pam.edit.all.form.name.ErrorMaxLength', { maxLength: 64 }) }),
			description: z
				.string()
				.max(255, { message: t('page.pam.edit.all.form.description.ErrorMaxLength', { maxLength: 255 }) })
				.optional(),
			hostname: z
				.string()
				.min(3, { message: t('page.pam.edit.all.form.hostname.Error', { type: 'RDP', minLength: 3 }) })
				.max(255, { message: t('page.pam.edit.all.form.hostname.ErrorMaxLength', { maxLength: 255 }) }),
			port: z
				.string()
				.refine((value) => /^\d+$/.test(value), { message: t('page.pam.edit.all.form.port.Error') })
				.refine((value) => parseInt(value, 10) >= 0 && parseInt(value, 10) <= 65535, {
					message: t('page.pam.edit.all.form.port.ErrorMaxLength', { maxLength: 65535 }),
				}),
			domain: z
				.string()
				.max(64, { message: t('page.pam.edit.all.form.domain.ErrorMaxLength', { maxLength: 64 }) })
				.optional(),
			username: z.string().optional(),
			password: z.string().optional(),
			recordingRetentionPeriod: z
				.string()
				.optional()
				.refine(
					(value) => {
						if (value === undefined) {
							return true;
						}

						const minutes = parse(value, 'minute');

						if (minutes === undefined || minutes === null) {
							return true;
						}

						return (
							minutes >= MIN_VALID_RETENTION_PERIOD_MINUTES &&
							minutes <= MAX_VALID_RETENTION_PERIOD_MINUTES
						);
					},
					{
						message: t('page.pam.edit.all.form.recordingRetentionPeriod.ErrorValueOutOfBound', {
							minRetentionMinutes: MIN_VALID_RETENTION_PERIOD_MINUTES,
							maxRetentionYears: Math.round(MAX_VALID_RETENTION_PERIOD_MINUTES / (365 * 24 * 60)),
						}),
					},
				),
			ignoreCertificate: z.boolean(),
			security: z.nativeEnum({
				Any: 'any',
				NLA: 'nla',
				'NLA-Ext': 'nla-ext',
				TLS: 'tls',
				'WM Connect': 'wmconnect',
				RDP: 'rdp',
			}),
			remoteAppName: z
				.string()
				.optional()
				.refine((value) => value === undefined || value === '' || value.length >= 5, {
					message: t('page.pam.edit.all.form.remoteAppName.Error', { minLength: 5 }),
				})
				.refine((value) => value === undefined || value === '' || value.length <= 255, {
					message: t('page.pam.edit.all.form.remoteAppName.ErrorMaxLength', { maxLength: 255 }),
				}),
			useExcaliburCredentials: z.boolean(),
			authorizationRequired: z.boolean(),
			remoteAppDirectory: z
				.string()
				.optional()
				.refine(
					(value) =>
						value === undefined ||
						value === '' ||
						/^(?:[a-zA-Z]:|\\\\[\w.]+\\[\w.$]+)\\?(?:[\w]+\\)*[\w.]*$/.test(value),
					{
						message: t('page.pam.edit.all.form.remoteAppDirectory.Error'),
					},
				)
				.refine((value) => value === undefined || value === '' || value.length <= 255, {
					message: t('page.pam.edit.all.form.remoteAppDirectory.ErrorMaxLength', { maxLength: 255 }),
				}),
			remoteAppArgs: z
				.string()
				.optional()
				.refine((value) => value === undefined || value === '' || value.length <= 255, {
					message: t('page.pam.edit.all.form.remoteAppArgs.ErrorMaxLength', { maxLength: 255 }),
				}),
			enableSessionRecording: z.boolean(),
			storeRecordingsPermanently: z.boolean(),
			enableFileDownload: z.boolean(),
			enableFileUpload: z.boolean(),
			uploadFileSizeLimit: z
				.string()
				.optional()
				.refine(
					(value) => {
						if (value === undefined) {
							return true;
						}

						const valueMB = convertMBtoBytes(value);

						return valueMB < MAX_UPLOAD_FILE_SIZE_BYTE && valueMB > MIN_UPLOAD_FILE_SIZE_MB;
					},
					{
						message: t('page.pam.edit.all.form.uploadFileSizeLimit.Error', {
							minUploadFileSizeLimitMB: MIN_UPLOAD_FILE_SIZE_MB,
							maxUploadFileSizeLimitMB: MAX_UPLOAD_FILE_SIZE_MB,
						}),
					},
				),
			enableClipboardCopy: z.boolean(),
			enableClipboardPaste: z.boolean(),
		})
		.refine(
			(data) => {
				if (!data.enableSessionRecording) {
					return true;
				}

				if (data.storeRecordingsPermanently) {
					return true;
				}

				if (data.recordingRetentionPeriod === undefined) {
					return false;
				}

				try {
					const timeInMin = parse(data.recordingRetentionPeriod, 'minute');

					return timeInMin !== undefined && timeInMin >= 1;
				} catch {
					return false;
				}
			},
			{
				message: t('page.pam.edit.all.form.recordingRetentionPeriod.Error'),
				path: ['recordingRetentionPeriod'],
			},
		)
		.refine(
			(data) => {
				if (data.useExcaliburCredentials) {
					return true;
				}
				if (!data.useExcaliburCredentials) {
					if (data.username && data.username.length > 1) {
						return true;
					}
				}

				return false;
			},
			{
				message: t('page.pam.edit.all.form.username.Error'),
				path: ['username'],
			},
		)
		.refine(
			(data) => {
				if (data.useExcaliburCredentials) {
					return true;
				}
				if (!data.useExcaliburCredentials) {
					if (data.username && data.username.length < 64) {
						return true;
					}
				}

				return false;
			},
			{
				message: t('page.pam.edit.all.form.username.ErrorMaxLength'),
				path: ['username'],
			},
		)
		.refine(
			(data) => {
				if (data.useExcaliburCredentials) {
					return true;
				}
				if (!data.useExcaliburCredentials && !isUpdating) {
					if (data.password && data.password.length > 1) {
						return true;
					}
				}

				if (!data.useExcaliburCredentials && isUpdating) {
					if (!data.password || data.password.length === 0) {
						return true;
					}
				}
				if (!data.useExcaliburCredentials && isUpdating) {
					if (data.password && data.password.length > 0) {
						return true;
					}
				}

				return false;
			},
			{
				message: t('page.pam.edit.all.form.password.Error'),
				path: ['password'],
			},
		)
		.refine(
			(data) => {
				if (data.useExcaliburCredentials) {
					return true;
				}
				if (!data.useExcaliburCredentials && !isUpdating) {
					if (data.password && data.password.length < 64) {
						return true;
					}
				}
				if (!data.useExcaliburCredentials && isUpdating) {
					if (data.password && data.password.length < 64) {
						return true;
					}
					if (!data.password) {
						return true;
					}
				}

				return false;
			},
			{
				message: t('page.pam.edit.all.form.password.ErrorMaxLength'),
				path: ['password'],
			},
		);
