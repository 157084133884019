import { TFunction } from 'i18next';
import { z } from 'zod';

import { getDurationInUnits } from '../../utils/DateTime';
import { MAX_OAUTH_ACCESS_TOKEN_VALIDITY_SEC, MIN_OAUTH_ACCESS_TOKEN_VALIDITY_SEC } from './constants';

export const getOAuthClientFormSchema = (t: TFunction) =>
	z.object({
		name: z.string().min(1, { message: t('page.oAuthClients.edit.form.name.Error') }),
		description: z.string().min(1, { message: t('page.oAuthClients.edit.form.description.Error') }),
		accessTokenValidity: z.string().refine(
			(value) => {
				const accessTokenValidityInSeconds = getDurationInUnits(value, 'second');

				return (
					accessTokenValidityInSeconds >= MIN_OAUTH_ACCESS_TOKEN_VALIDITY_SEC &&
					accessTokenValidityInSeconds <= MAX_OAUTH_ACCESS_TOKEN_VALIDITY_SEC
				);
			},
			{
				message: t('page.oAuthClients.edit.form.accessTokenValidity.Error', {
					minOAuthTokenValiditySec: MIN_OAUTH_ACCESS_TOKEN_VALIDITY_SEC,
					maxOAuthTokenValidityYears: Math.floor(MAX_OAUTH_ACCESS_TOKEN_VALIDITY_SEC / 60 / 60 / 24 / 365),
				}),
			},
		),
	});

export const oAuthClientListSchema = z.object({
	clientID: z.string(),
	name: z.string(),
	createdAt: z.string(),
	expiresAt: z.string().nullable(),
});
