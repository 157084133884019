import parse from 'parse-duration';
import { z } from 'zod';
import { TFunction } from 'i18next';
import { EAuthenticationType } from '../../../enums/pam/EAuthenticationType';
import {
	MIN_VALID_RETENTION_PERIOD_MINUTES,
	MAX_VALID_RETENTION_PERIOD_MINUTES,
	MAX_FILE_SIZE,
	MAX_UPLOAD_FILE_SIZE_BYTE,
	MIN_UPLOAD_FILE_SIZE_MB as MIN_UPLOAD_FILE_SIZE_MB,
	MAX_UPLOAD_FILE_SIZE_MB as MAX_UPLOAD_FILE_SIZE_MB,
} from '../constants';
import { convertMBtoBytes } from '../../../utils/FormatSize';

export const getPAMSshTargetFormSchema = (t: TFunction, authenticationType: EAuthenticationType, isUpdating: boolean) =>
	z
		.object({
			name: z
				.string()
				.min(3, { message: t('page.pam.edit.all.form.name.Error', { minLength: 3 }) })
				.max(64, { message: t('page.pam.edit.all.form.name.ErrorMaxLength', { maxLength: 64 }) }),
			description: z
				.string()
				.max(255, { message: t('page.pam.edit.all.form.description.ErrorMaxLength', { maxLength: 255 }) })
				.optional(),
			hostname: z
				.string()
				.min(3, { message: t('page.pam.edit.all.form.hostname.Error', { type: 'SSH', minLength: 3 }) })
				.max(255, { message: t('page.pam.edit.all.form.hostname.ErrorMaxLength', { maxLength: 255 }) }),
			port: z
				.string()
				.refine((value) => /^\d+$/.test(value), { message: t('page.pam.edit.all.form.port.Error') })
				.refine((value) => parseInt(value, 10) >= 0 && parseInt(value, 10) <= 65535, {
					message: t('page.pam.edit.all.form.port.ErrorMaxLength', { maxLength: 65535 }),
				}),
			useExcaliburCredentials: z.boolean(),
			authorizationRequired: z.boolean(),
			username: z.string().optional(),
			password: z.string().optional(),
			privateKey: z.string().optional(),
			passphrase: z
				.string()
				.optional()
				.refine((value) => value === undefined || value === '' || value.length >= 5, {
					message: t('page.pam.edit.all.form.passphrase.Error', { minLength: 5 }),
				}),
			recordingRetentionPeriod: z
				.string()
				.optional()
				.refine(
					(value) => {
						if (value === undefined) {
							return true;
						}

						const minutes = parse(value, 'minute');

						if (minutes === undefined || minutes === null) {
							return true;
						}

						return (
							minutes >= MIN_VALID_RETENTION_PERIOD_MINUTES &&
							minutes <= MAX_VALID_RETENTION_PERIOD_MINUTES
						);
					},
					{
						message: t('page.pam.edit.all.form.recordingRetentionPeriod.ErrorValueOutOfBound', {
							minRetentionMinutes: MIN_VALID_RETENTION_PERIOD_MINUTES,
							maxRetentionYears: Math.round(MAX_VALID_RETENTION_PERIOD_MINUTES / (365 * 24 * 60)),
						}),
					},
				),
			rootDirectory: z.string().optional(),
			enableSessionRecording: z.boolean(),
			storeRecordingsPermanently: z.boolean(),
			enableFileDownload: z.boolean(),
			enableFileUpload: z.boolean(),
			uploadFileSizeLimit: z
				.string()
				.optional()
				.refine(
					(value) => {
						if (value === undefined) {
							return true;
						}

						const valueMB = convertMBtoBytes(value);

						return valueMB < MAX_UPLOAD_FILE_SIZE_BYTE && valueMB > MIN_UPLOAD_FILE_SIZE_MB;
					},
					{
						message: t('page.pam.edit.all.form.uploadFileSizeLimit.Error', {
							minUploadFileSizeLimitMB: MIN_UPLOAD_FILE_SIZE_MB,
							maxUploadFileSizeLimitMB: MAX_UPLOAD_FILE_SIZE_MB,
						}),
					},
				),
			enableClipboardCopy: z.boolean(),
			enableClipboardPaste: z.boolean(),
			file: z
				.instanceof(File)
				.optional()
				.nullable()
				.refine((file) => !file || file.size <= MAX_FILE_SIZE, {
					message: t('page.pam.edit.all.form.file.ErrorMaxLength'),
				}),
		})
		.refine(
			(data) => {
				if (!data.enableSessionRecording) {
					return true;
				}

				if (data.storeRecordingsPermanently) {
					return true;
				}

				if (data.recordingRetentionPeriod === undefined) {
					return false;
				}

				try {
					const timeInMin = parse(data.recordingRetentionPeriod, 'minute');

					return timeInMin !== undefined && timeInMin >= 1;
				} catch {
					return false;
				}
			},
			{
				message: t('page.pam.edit.all.form.recordingRetentionPeriod.Error'),
				path: ['recordingRetentionPeriod'],
			},
		)
		.refine(
			(data) => {
				if (!data.enableFileUpload) {
					return true;
				}
				if (!data.enableFileDownload) {
					return true;
				}

				if (data.rootDirectory === '') {
					return true;
				}

				return data.rootDirectory === undefined || /^(\/[^/ ]*)+\/?$/.test(data.rootDirectory);
			},
			{
				message: t('page.pam.edit.all.form.rootDirectory.Error'),
				path: ['rootDirectory'],
			},
		)
		.refine(
			(data) => {
				if (!data.enableSessionRecording) {
					return true;
				}

				if (data.storeRecordingsPermanently) {
					return true;
				}

				if (data.recordingRetentionPeriod === undefined) {
					return false;
				}

				try {
					const timeInMin = parse(data.recordingRetentionPeriod, 'minute');

					return timeInMin !== undefined && timeInMin >= 1;
				} catch {
					return false;
				}
			},
			{
				message: t('page.pam.edit.all.form.recordingRetentionPeriod.Error'),
				path: ['recordingRetentionPeriod'],
			},
		)
		.refine(
			(data) => {
				if (data.useExcaliburCredentials) {
					return true;
				}

				if (authenticationType === EAuthenticationType.PRIVATEKEY && isUpdating) {
					if (
						!data.privateKey ||
						data.privateKey.length < 10 ||
						!/^-----BEGIN (.*) PRIVATE KEY-----\n([\s\S]*)\n-----END \1 PRIVATE KEY-----$/m.test(
							data.privateKey,
						)
					) {
						return false;
					}
				}

				return true;
			},
			{
				message: t('page.pam.edit.all.form.privateKey.ErrorFormat'),
				path: ['privateKey'],
			},
		)
		.refine(
			(data) => {
				if (data.useExcaliburCredentials) {
					return true;
				}

				if (authenticationType === EAuthenticationType.PRIVATEKEY && !isUpdating) {
					if (
						!data.privateKey ||
						data.privateKey.length < 10 ||
						!/^-----BEGIN (.*) PRIVATE KEY-----\n([\s\S]*)\n-----END \1 PRIVATE KEY-----$/m.test(
							data.privateKey,
						)
					) {
						return false;
					}
				}

				return true;
			},
			{
				message: t('page.pam.edit.all.form.privateKey.ErrorFormat'),
				path: ['privateKey'],
			},
		)
		.refine(
			(data) => {
				if (data.useExcaliburCredentials) {
					return true;
				}
				if (authenticationType === EAuthenticationType.PRIVATEKEY && isUpdating) {
					if (!data.privateKey || data.privateKey.length === 0) {
						return false;
					}
				}

				return true;
			},
			{
				message: t('page.pam.edit.all.form.privateKey.ErrorFormat'),
				path: ['privateKey'],
			},
		)
		.refine(
			(data) => {
				if (data.useExcaliburCredentials) {
					return true;
				}
				if (authenticationType === EAuthenticationType.PASSWORD && !isUpdating) {
					if (data.password === undefined || data.password === '' || data.password.length <= 1) {
						return false;
					}
				}

				return true;
			},
			{
				message: t('page.pam.edit.all.form.password.Error'),
				path: ['password'],
			},
		)
		.refine(
			(data) => {
				if (data.useExcaliburCredentials) {
					return true;
				}
				if (authenticationType === EAuthenticationType.PASSWORD) {
					if (data.password && data.password.length > 64) {
						return false;
					}
				}

				return true;
			},
			{
				message: t('page.pam.edit.all.form.password.ErrorMaxLength', { maxLength: 64 }),
				path: ['password'],
			},
		)
		.refine(
			(data) => {
				if (data.useExcaliburCredentials) {
					return true;
				}
				if (authenticationType === EAuthenticationType.PASSWORD && isUpdating) {
					if (!data.password || data.password.length === 0) {
						return true;
					}
				}
				if (authenticationType === EAuthenticationType.PASSWORD && isUpdating) {
					if (data.password && data.password.length <= 1) {
						return false;
					}
				}

				return true;
			},
			{
				message: t('page.pam.edit.all.form.password.UpdateError'),
				path: ['password'],
			},
		)
		.refine(
			(data) => {
				if (data.useExcaliburCredentials) {
					return true;
				}
				if (data.username && data.username.length > 64) {
					return false;
				}

				return true;
			},
			{
				message: t('page.pam.edit.all.form.username.ErrorMaxLength', { maxLength: 64 }),
				path: ['username'],
			},
		);
