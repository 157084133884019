import { LinkProps, useHref, useLinkClickHandler } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';

import { getTenantFromPath } from '../../utils/Routing';
import { useUnsavedChangesContext } from '../../hooks/useUnsavedChangesContext';

export const Link: React.FC<LinkProps> = (props): JSX.Element => {
	const { hasUnsavedChanges, setShowDialog, setPathParams } = useUnsavedChangesContext();

	const { tenantID, tenantSlug } = getTenantFromPath();

	let to = props.to;
	if (tenantSlug) {
		to = `/tenant/${tenantSlug}${to}`;
	} else if (tenantID) {
		to = `/tenant/${tenantID}${to}`;
	}

	const href = useHref(to);
	const handleClick = useLinkClickHandler(to);

	return (
		<MuiLink
			{...props}
			href={href}
			onClick={(event) => {
				if (hasUnsavedChanges) {
					event.preventDefault();

					setShowDialog(true);
					setPathParams({ to: href });

					return;
				}

				if (event.defaultPrevented) {
					return;
				}

				handleClick(event);
			}}
		/>
	);
};
